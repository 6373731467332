const routes = [
  //众创空间列表
  {
    path: "/futureCreateWork/workArea",
    name: "workArea",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workArea"], resolve),
  },
  //众创空间审核
  {
    path: "/futureCreateWork/workAreaForm",
    name: "workAreaForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workAreaForm"], resolve),
  },
  //众创空间申请列表
  {
    path: "/futureCreateWork/createWork",
    name: "createWork",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/index"], resolve),
  },
  //众创空间申请
  {
    path: "/futureCreateWork/workForm",
    name: "workForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workForm"], resolve),
  },
  //雅曼创业首页
  {
    path: "/futureCreateWork/home",
    name: "futureCreateWork-home",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/home/index"], resolve),
  },
  //创业达人列表
  {
    path: "/futureCreateWork/createWorkTalent",
    name: "createWorkTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTalent/index"], resolve),
  },
  //创业达人详情
  {
    path: "/futureCreateWork/createWorkTalentDetail",
    name: "createWorkTalentDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTalent/createWorkTalentDetail",
      ], resolve),
  },
  //创业导师
  {
    path: "/futureCreateWork/createWorkTeacher",
    name: "createWorkTeacher",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTeacher/index"], resolve),
  },
  //新增创业导师
  {
    path: "/futureCreateWork/createWorkTeacherForm",
    name: "createWorkTeacherForm",
    meta: {
      title: "",
      echo: "/futureCreateWork/createWorkTeacher",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTeacher/createWorkTeacherForm",
      ], resolve),
  },

  //服务联盟
  {
    path: "/futureCreateWork/serviceLeague",
    name: "serviceLeague",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/serviceLeague/index"], resolve),
  },
  //服务联盟
  {
    path: "/futureCreateWork/serviceLeagueForm",
    name: "serviceLeagueForm",
    meta: {
      title: "",
      echo: "/futureCreateWork/serviceLeague",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/serviceLeague/serviceLeagueForm",
      ], resolve),
  },
  //场所租赁
  {
    path: "/futureCreateWork/rentPlace",
    name: "rentPlace",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/index"], resolve),
  },
  //场所租赁详情
  {
    path: "/futureCreateWork/rentPlaceDetail",
    name: "rentPlaceDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceDetail"], resolve),
  },
  //场所租赁申请
  {
    path: "/futureCreateWork/rentPlaceSubmit",
    name: "rentPlaceSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceSubmit"], resolve),
  },
  //场所租赁结果
  {
    path: "/futureCreateWork/rentSuccess",
    name: "rentSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentSuccess"], resolve),
  },
  // 幸运抽奖-列表
  {
    path: "/futureCreateWork/luckyDrawList",
    name: "luckyDrawList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/luckyDraw/luckyDrawList"], resolve),
  },
  // 幸运抽奖-详情
  {
    path: "/futureCreateWork/luckyDrawDetail",
    name: "luckyDrawDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/luckyDraw/luckyDrawDetail"], resolve),
  },
];

export default routes;
