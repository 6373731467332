const routes = [
  //防疫登记
  //首页
  {
    path: "/futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  //防疫资讯
  {
    path: "/futureTreat/epidemicNews",
    name: "epidemicNews",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicNews.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRegister",
    name: "epidemicRegister",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicRegister.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRes",
    name: "epidemicRes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/epidemicRes.vue"], resolve),
  },
];

export default routes;
