const routes = [
  //教育地图
  {
    path: "/futureEdu/eduMap",
    name: "eduMap",
    meta: {
      title: "教育地图",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/eduMap/index"], resolve),
  },
  //雅曼云校
  {
    path: "/futureEdu/cloudSchool",
    name: "cloudSchool",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/index"], resolve),
  },
  //课程列表
  {
    path: "/futureEdu/courseList",
    name: "courseList",
    meta: {
      title: "",
      keepAlive: false,
      isUseCache: false,
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseList"], resolve),
  },
  //课程详情
  {
    path: "/futureEdu/courseDetail",
    name: "courseDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseDetail"], resolve),
  },
  /** 雅曼托幼 */
  // 雅曼托幼首页
  {
    path: "/futureEdu/entrustChild",
    name: "entrustChild",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/index.vue"], resolve),
  },
  //托管说明
  {
    path: "/futureEdu/entrustChildInfo",
    name: "entrustChildInfo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildInfo.vue"], resolve),
  },
  //雅曼托幼
  {
    path: "/futureEdu/entrustChildList",
    name: "entrustChildList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildList.vue"], resolve),
  },
  //雅曼托幼审核
  {
    path: "/futureEdu/entrustManager",
    name: "entrustManager",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustManager.vue"], resolve),
  },
  //我的预约
  {
    path: "/futureEdu/myEntrustChild",
    name: "myEntrustChild",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/myEntrustChild.vue"], resolve),
  },
  //雅曼托幼视频
  {
    path: "/futureEdu/entrustChildVideo",
    name: "entrustChildVideo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/entrustChild/entrustChildVideo.vue",
      ], resolve),
  },
  // 托幼签到
  {
    path: "/futureEdu/kindergartensSign",
    name: "kindergartensSign",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/kindergartensSign/index.vue"], resolve),
  },
  /** end */
];

export default routes;
