import wx from "weixin-js-sdk";
import store from "@/store";
import axios from "@/axios";
import { Toast } from "vant";
var moment = require("moment");
//判断是否需要房号
async function getAuth(name) {
  let api = `/gateway/blade-system/application/getFunctionSetting`;
  let params = {
    tenantId: store.state.tenantId,
    applicationName: name,
    functionName: "需要园区房号",
  };
  return await axios.get(api, { params: params }).then((res) => {
    if (res.code == 200) {
      return res.data.settingOn;
    } else {
      return false;
    }
  });
}
async function toRegister(toUrl, params, name) {
  let res = await getAuth(name);
  console.log(res);
  if (res == 2) {
    //需要园区房号 houseId
    if (!store.state.houseId || store.state.houseId == 0) {
      if (!store.state.userId || store.state.userId == 0) {
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
        return false;
      } else {
        if (!store.state.communityId || store.state.communityId == 0) {
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isAddRoom=1&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
          return false;
        } else {
          let isRegister = 1;
          let assetId = store.state.assetId;
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isRegister=${isRegister}&assetId=${assetId}&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
          return false;
        }
      }
    } else {
      if (store.state.assetAuditStatus == 2) {
        Toast("你的房号被驳回,请重新提交");
        return false;
      }
      if (store.state.assetAuditStatus == 0) {
        Toast("你的房号待审核");
        return false;
      }
      return true;
    }
  } else if (res == 1) {
    //需要园区，不需要房号 communityId
    if (!store.state.communityId || store.state.communityId == 0) {
      if (!store.state.userId || store.state.userId == 0) {
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
        return false;
      } else {
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isAddRoom=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
        return false;
      }
    } else {
      return true;
    }
  } else {
    //都不需要
    return true;
  }
}

async function isWxMiniPro() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
    try {
      await wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
}
//  埋点
async function gloabalCount(relationId, dataType, operationType) {
  let api = `/gateway/hc-external/redisMQ/produce`;
  let params = {
    userId: store.state.userId,
    miniUserId: store.state.openId,
    tenantId: store.state.tenantId,
    communityId: store.state.communityId,
    roomId: store.state.houseId,
    relationId: relationId,
    dataType: dataType,
    operationType: operationType,
    operationTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  let res = await axios.post(api, params);
  if (res.code == 200) {
  }
}
// 通过opts生成map
const generateMapByOpts = (opts) => {
  let map = {};
  if (Array.isArray(opts)) {
    opts.forEach((item) => {
      if (item.value !== undefined) {
        map[item.value] = item.label;
      }
    });
  } else {
    throw new Error("opts must be an array");
  }
  return map;
};

const optionsGenerator = (options) => {
  return (option) => {
    let _option = [];
    if (option) {
      if (option === 1) {
        _option.push({
          label: "全部",
          value: undefined,
        });
      } else if (option === 2) {
        _option.push({
          label: "请选择",
          value: undefined,
        });
      } else {
        _option = [options];
      }
    }
    Array.isArray(options) &&
      options.forEach((item) => {
        let tempObj = {};
        tempObj = { ...item };
        _option.push(tempObj);
      });
    return _option;
  };
};
const mapHelper = {
  setMap(options) {
    const map = generateMapByOpts(options);
    const setOps = optionsGenerator(options);
    return {
      map,
      setOps,
    };
  },
  setOptionGroup(list, params) {
    let { valueKey, labelKey, parentIdKey, defaultText, keys } = params;
    valueKey = valueKey || "id";
    labelKey = labelKey || "name";
    parentIdKey = parentIdKey || "parentId";

    let optionGroup = [];
    if (Array.isArray(list) && list.length) {
      let options = [];
      list.forEach((item) => {
        const parentId = item[parentIdKey];
        if ([undefined, null, ""].includes(parentId)) {
          optionGroup.push({
            label: item[labelKey],
            value: item[valueKey],
            options: [],
          });
        } else {
          options.push(item);
        }
      });
      options.forEach((option) => {
        const parentId = option[parentIdKey];
        const parentItem = optionGroup.find((item) => {
          return item.value === parentId;
        });
        const _option = {
          label: option[labelKey],
          value: option[valueKey],
        };
        if (keys && keys.length) {
          keys.forEach((key) => {
            _option[key] = option[key];
          });
        }
        parentItem.options.push(_option);
      });
    }

    if (defaultText) {
      optionGroup.unshift({
        label: defaultText,
        value: undefined,
        options: [
          {
            label: defaultText,
            value: undefined,
          },
        ],
      });
    }
    return optionGroup;
  },
};
export { mapHelper, toRegister, isWxMiniPro, getAuth, gloabalCount };
