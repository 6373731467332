<template>
  <van-checkbox
    v-model="isChecked"
    :shape="shape"
    :checked-color="checkedColor"
    @change="handelChange"
  >
    {{ label }}
  </van-checkbox>
</template>

<script>
import Vue from "vue";
import { Checkbox, CheckboxGroup } from "vant";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
      default: "square",
    },
    checkedColor: {
      type: String,
      default: "#1989fa",
    },
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
  watch: {
    isChecked(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    handelChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
